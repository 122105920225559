import React from "react";
import Header from "../elements/Header";

function Headers() {
  return (
    <header>
      <Header />
    </header>
  );
}

export default Headers;
